import { Alert, Snackbar, Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, Typography, IconButton, DialogActions } from "@mui/material"
import { Fragment, useState } from "react"
import Breadcrumb from "../../registri/shared/breadcrumb"
import ElencoCorsiFormatore from "../elencoCorsiFormatore"
import { useNavigate } from "react-router-dom";
import { convertDataDiNascita, convertDataGetProssimeLezione } from "../../shared/metodiDateUtility";
import { TirociniAPI } from "../../../api/tirociniApi";
import { ArrowBack, EditCalendar } from "@mui/icons-material";
import RangeDate from "../../shared/rangeDate";
import { convertISODate } from "../../shared/metodiDateUtility";
import { Help, Close } from "@mui/icons-material";
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';
import LegendaRegistroSintesiTirocinio from "./legendaRegistroSintesiTirocinio";
import { DateTime } from "luxon";
import TableRegistroTirocinio from "./tableRegistro";

export default function RegistroTirocinioAlunno() {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const [hasLoaded, setHasLoaded] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isConfermaSalva, setIsConfermaSalva] = useState(false);
    const [isDialogConfermaOpen, setIsDialogConfermaOpen] = useState(false);

    const [dialogSelezionaCorsoOpen, setDialogSelezionaCorsoOpen] = useState(true)
    const [idCorsoSelezionato, setIdCorsoSelezionato] = useState(-1)
    const [nomeCorso, setNomeCorso] = useState("")
    const [sedeUtente, setSedeUtente] = useState("");

    const [dataDiInteresseInizio, setDataDiInteresseInizio] = useState(null);
    const [dataDiInteresseFine, setDataDiInteresseFine] = useState(null);
    const [formattedDataDiInteresseInizio, setFormattedDataDiInteresseInizio] = useState("");
    const [formattedDataDiInteresseFine, setFormattedDataDiInteresseFine] = useState("");
    const [changePeriodoDiInteresseOpen, setChangePeriodoInteresseOpen] = useState(false)

    const [dialogLegendaOpen, setDialogLegendaOpen] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [alertMsg, setAlertMsg] = useState(false);
    const [annoFormativo, setAnnoFormativo] = useState("20232024");
    const [nomeAlunnoSelezionato, setNomeAlunnoSelezionato] = useState("");
    const [idAlunnoSelezionato, setIdAlunnoSelezionato] = useState(-1);
    const [canEditPresenze, setCanEditPresenze] = useState(false);
    const [alunnoObj, setAlunnoObj] = useState(null);
    const [listDays, setListDays] = useState([]);
    const [idAlunnoPrecedente, setIdAlunnoPrecedente] = useState(null);
    const [idAlunnoSuccessivo, setIdAlunnoSuccessivo] = useState(null);

    function goToHome() {
        navigate("/")
    }

    function openChangeCorso() {
        setDialogSelezionaCorsoOpen(true);
        setHasLoaded(false);
    }

    function changeIdClasseSelezionata(idCorso, nomeCorso, codiceCorso, codiceSimon, nomeSede, dataDiInteresse, annoFormativoTmp, idAlunno, nomeAlunno) {

        setAnnoFormativo(annoFormativoTmp);
        setDialogSelezionaCorsoOpen(false);

        setIdCorsoSelezionato(idCorso);
        setNomeCorso(codiceCorso + " - " + nomeCorso + " - " + nomeSede);

        setSedeUtente(nomeSede)

        let dataDiInteresseInizioConverted = convertDataGetProssimeLezione(dataDiInteresse, true);
        setDataDiInteresseInizio(dataDiInteresseInizioConverted);
        let dataDiInteresseInizioFormatted = convertDataDiNascita(dataDiInteresse);
        setFormattedDataDiInteresseInizio(dataDiInteresseInizioFormatted);

        let dataDiInteresseFineTmp = dataDiInteresse.endOf('week');
        let dataDiInteresseFineConverted = convertDataGetProssimeLezione(dataDiInteresseFineTmp, true);
        setDataDiInteresseFine(dataDiInteresseFineConverted);
        let dataDiInteresseFineFormatted = convertDataDiNascita(dataDiInteresseFineTmp);
        setFormattedDataDiInteresseFine(dataDiInteresseFineFormatted);
        setIdAlunnoSelezionato(idAlunno);
        setNomeAlunnoSelezionato(nomeAlunno);
        getRegistroTirocinioAlunno(idAlunno, dataDiInteresseInizioConverted, dataDiInteresseFineConverted, annoFormativoTmp);
    }


    function openPeriodoDiInteresseDialog() {
        setChangePeriodoInteresseOpen(true);
    }

    function changePeriodoDiInteresse(dataInizio, dataFine) {
        let today = DateTime.local();
        let dataInizioTmp = dataInizio ?? today.toISO();
        let dataFineTmp = dataFine ?? today.toISO();

        setDataDiInteresseInizio(dataInizioTmp);
        setDataDiInteresseFine(dataFineTmp);

        setFormattedDataDiInteresseInizio(convertISODate(dataInizio));
        setFormattedDataDiInteresseFine(convertISODate(dataFine));

        setChangePeriodoInteresseOpen(false)

        getRegistroTirocinioAlunno(idAlunnoSelezionato, dataInizio, dataFine, annoFormativo);
    }


    function openDialogLegenda() {
        setDialogLegendaOpen(true);
    }
    function closeDialogLegenda() {
        setDialogLegendaOpen(false);
    }

    async function getRegistroTirocinioAlunno(idAlunnoSelezionato, dataDiInteresseInizio, dataDiInteresseFine,annoFormativoTmp) {
        let dataObj = {
            "idAlunno": idAlunnoSelezionato,
            "dataDa": dataDiInteresseInizio,
            "dataA": dataDiInteresseFine,
            "annoFormativo": annoFormativoTmp
        }
        TirociniAPI.getRegistroAlunno(dataObj)
            .then((result) => {
                setNomeAlunnoSelezionato(result?.alunno?.nome + " " + result?.alunno?.cognome)
                setIdAlunnoPrecedente(result?.alunnoPrecedente ?? null);
                setIdAlunnoSuccessivo(result?.alunnoSuccessivo ?? null);
                setAlunnoObj(result?.alunno);
                setListDays([...result?.giorniOrarioTirocinio]);
                setCanEditPresenze(result?.canEditPresenze ?? false);
                setHasLoaded(true);
            })
            .catch((error) => { })
    }

    function goToAlunnoPrecedente(){
        getRegistroTirocinioAlunno(idAlunnoPrecedente, dataDiInteresseInizio, dataDiInteresseFine, annoFormativo)
    }

    function goToAlunnoSuccessivo(){
        getRegistroTirocinioAlunno(idAlunnoSuccessivo, dataDiInteresseInizio, dataDiInteresseFine, annoFormativo)
    }


    return (
        <Fragment>
            {hasLoaded ? (
                <Fragment>
                    <Breadcrumb sections={["Registro di sintesi tirocinio", nomeCorso]} isTirocinio={true} />
                    <Box sx={{ px: 5, minHeight: '80vh' }}>
                        {listDays.length > 0 ? (
                            <TableRegistroTirocinio goToAlunnoSuccessivo={goToAlunnoSuccessivo} goToAlunnoPrecedente={goToAlunnoPrecedente} idAlunnoPrecedente={idAlunnoPrecedente} idAlunnoSuccessivo={idAlunnoSuccessivo} annoFormativo={annoFormativo} formattedDataDiInteresseInizio={formattedDataDiInteresseInizio} formattedDataDiInteresseFine={formattedDataDiInteresseFine} openPeriodoDiInteresseDialog={openPeriodoDiInteresseDialog} openDialogLegenda={openDialogLegenda} openChangeCorso={openChangeCorso}
                                canEditPresenze={canEditPresenze} nomeAlunno={nomeAlunnoSelezionato} nomeCorso={nomeCorso} idCorso={idCorsoSelezionato} idAlunno={idAlunnoSelezionato} listDays={listDays} />
                        ) : (
                            <Box height={"80vh"} display={"flex"} flexDirection={"column"} justifyContent={"center"}>
                                <Alert severity="info">Nessuna giornata di tirocinio nel periodo selezionato ({formattedDataDiInteresseInizio} - {formattedDataDiInteresseFine})</Alert>
                                <Button startIcon={<ArrowBack />} onClick={openChangeCorso} variant="contained" sx={{ mt: 2 }}>  Torna alla selezione del corso</Button>
                                <Button sx={{ mt: 1, width: { xs: '100%', md: 'unset' } }} variant="outlined" startIcon={<EditCalendar />} onClick={openPeriodoDiInteresseDialog}>
                                    Cambia periodo di interesse ({formattedDataDiInteresseInizio} - {formattedDataDiInteresseFine})
                                </Button>
                               

                            </Box>
                        )}
                    </Box>
                </Fragment>
            ) : (
                <Box display="flex" flexDirection={"column"} alignItems="center" justifyContent={"center"} height={"90vh"}>
                    <CircularProgress />
                    <Typography sx={{ mt: 2 }}>Caricamento...</Typography>
                </Box>
            )}

            <Dialog fullWidth maxWidth={"md"} open={dialogSelezionaCorsoOpen} sx={{ mt: 8 }}>
                <DialogTitle variant="h6"><b>Seleziona corso</b></DialogTitle>
                <Divider />
                <DialogContent>
                    <ElencoCorsiFormatore showDateRange={true} mustChooseAlunno={true}
                        sedeUtente={sedeUtente} goToHome={goToHome} changeCorsoSelezionato={changeIdClasseSelezionata} />
                </DialogContent>
            </Dialog>
            <Dialog open={changePeriodoDiInteresseOpen} >
                <DialogTitle variant="h6">
                    Seleziona il periodo di interesse
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <RangeDate  canShowDateGreaterThanToday={true} idClasse={idCorsoSelezionato} dataDiInteresseInizio={dataDiInteresseInizio} dataDiInteresseFine={dataDiInteresseFine} confirmPeriodo={changePeriodoDiInteresse} />
                </DialogContent>
            </Dialog>
            <Dialog sx={{ marginTop: '7vh' }} fullWidth maxWidth="xl" open={dialogLegendaOpen}>
                <DialogTitle display={"flex"} alignItems={"center"} justifyContent={"space-between"} >
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                        Legenda
                    </Typography>
                    <IconButton onClick={closeDialogLegenda} sx={{ ml: 5 }}  >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <LegendaRegistroSintesiTirocinio />
                </DialogContent>
                <Divider />
                <DialogActions sx={{ p: 3 }}>
                    <Button onClick={closeDialogLegenda} variant="contained" fullWidth color="primary">Chiudi finestra</Button>
                </DialogActions>
            </Dialog>



            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={alertOpen} autoHideDuration={3000} onClose={() => { setAlertOpen(false) }}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertSeverity} sx={{ width: '100%' }}>
                    {alertMsg}
                </Alert>
            </Snackbar>

            <Helmet>
                <title>{t("name")} - Registro tirocinio</title>
            </Helmet>
        </Fragment>
    )
}